import React, {useState, KeyboardEvent, useEffect} from 'react';
import {Link as RouterLink} from "react-router-dom";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MapIcon from '@mui/icons-material/Map';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CheckIcon from '@mui/icons-material/Check';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RoutingToolLogo from './RoutingToolLogo.png';
import Day from './Day';
import AutoFocusTextField from './AutoFocusTextField';
import Guid from './Guid';
import ImportDay from './ImportDay';
import LogoutIcon from '@mui/icons-material/Logout';
import RefreshIcon from '@mui/icons-material/Refresh';

type HomeProps = {
  days: Day[],
  setDays: (days: Day[]) => void,
  addDay: (title: string) => Promise<void>,
  addLeg: (dayId: string) => Promise<void>,
  deleteDay: (id: string) => Promise<void>,
  deleteLeg: (dayId: string, legId: string) => Promise<void>,
  editDayTitle: (id: string) => Promise<void>,
  editLegTitle: (dayId: string, legId: string) => Promise<void>,
  updateDayTitle: (id: string, newTitle: string) => Promise<void>,
  updateLegTitle: (dayId: string, legId: string, newTitle: string) => Promise<void>,
  endpoint: string
}

const Home = ({ days, addDay, setDays, addLeg, deleteDay, deleteLeg, editDayTitle, editLegTitle, updateDayTitle, updateLegTitle, endpoint }: HomeProps) => {
  const [confirmDayDeleteData, setConfirmDayDeleteData] = useState<[boolean, string?]>([false, undefined]);
  const [confirmLegDeleteData, setConfirmLegDeleteData] = useState<[boolean, [string?, string?]?]>([false, undefined]);
  const [addDayDialogOpen, setAddDayDialogOpen] = useState(false);
  const [dayTitleValue, setDayTitleValue] = useState<string>("");

  useEffect(() => {
    requestData();
  }, []);
  
  const onRefreshButtonClick = async () => {
    await requestData();
  }
  
  const requestData = async () => {
    let response = await fetch(endpoint, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Authorization': 'Basic ' + btoa('appuser:JqmaNfTJz4wapTh38nkqnt5XejcVN5')
      }
    });
    
    let data = await response.json();
    const importedObject = data as ImportDay[];
    const importData = importedObject.map(obj => {
      return {
        id: Guid.newGuid(),
        title: obj.title,
        legs: obj.item.map(i => {
          return {
            id: Guid.newGuid(),
            title: i.title,
            route: i.route.map(r => {
              return {
                id: Guid.newGuid(),
                type: r.type,
                coordinates: {
                  lat: r.lat,
                  lng: r.lng,
                }
              }
            }),
            editMode: false,
          }
        }),
        editMode: false,
      }
    })

    setDays(importData);
  }
  
  const onSignOutButtonClick = () => {
    localStorage.clear();
    window.location.reload();
  }

  const handleConfirmDeleteClose = () => {
    setConfirmDayDeleteData([false, undefined]);
    setConfirmLegDeleteData([false, undefined]);
  };

  const handleDayClose = () => {
    setAddDayDialogOpen(false);
    setDayTitleValue("");
  }

  const onAddDay = async () => {
    await addDay(dayTitleValue);
    setDayTitleValue("");
    setAddDayDialogOpen(false);
  }

  const onDeleteDay = async () => {
    if (!confirmDayDeleteData[1]) return;
    await deleteDay(confirmDayDeleteData[1]!);
    setConfirmDayDeleteData([false, undefined]);
  }

  const onDeleteLeg = async () => {
    if (!confirmLegDeleteData[1]) return;
    await deleteLeg(confirmLegDeleteData[1]![0]!, confirmLegDeleteData[1]![1]!);
    setConfirmLegDeleteData([false, undefined]);
  }

  function handleEnterKeyPress(e: KeyboardEvent<HTMLDivElement>) {
    if (e.keyCode === 13) {
      (e.target as HTMLInputElement).blur();
    }
  }

  function handleEnterKeyPressOnDialog(e: KeyboardEvent<HTMLDivElement>) {
    if (e.keyCode === 13 && dayTitleValue.length > 0) {
      e.preventDefault();
      onAddDay();
    }
  }

  return (
    <Box>
      <img style={{ display: 'block', marginTop: '150px', marginLeft: 'auto', marginRight: 'auto' }} width={400} draggable={false} src={RoutingToolLogo}  alt={'logo'}/>
      <Box sx={{ width: '60%', marginLeft: 'auto', marginRight: 'auto', marginTop: '64px', paddingBottom: '128px' }}>
        <Typography sx={{ display: 'inline-block' }} variant="h3">Agenda</Typography>
        <Box sx={{ float: 'right', marginTop: '12px' }}>
          <Button variant="text" startIcon={<AddIcon />} sx={{ marginRight: '8px' }} disableFocusRipple onClick={() => setAddDayDialogOpen(true)}>Add Day</Button>
          {/*<input*/}
          {/*  accept=".json,application/json"*/}
          {/*  style={{ display: 'none' }}*/}
          {/*  id="raised-button-file"*/}
          {/*  type="file"*/}
          {/*  onChange={handleImport}*/}
          {/*/>*/}
          {/*<label htmlFor="raised-button-file">*/}
          {/*  <Button variant="text" component="span" startIcon={<CloudUploadIcon />} sx={{ marginRight: '8px' }} disableFocusRipple>Import</Button>*/}
          {/*</label>*/}
          {/*<Button variant="text" startIcon={<CloudDownloadIcon />} sx={{ marginRight: '0px' }} disableFocusRipple onClick={onExportButtonClick}>Export</Button>*/}
          <Button variant="text" startIcon={<RefreshIcon />} sx={{ marginRight: '8px' }} disableFocusRipple onClick={onRefreshButtonClick}>Refresh</Button>
          <Button variant="text" startIcon={<LogoutIcon />} sx={{ marginRight: '0px' }} disableFocusRipple onClick={onSignOutButtonClick}>Sign Out</Button>
        </Box>
        <Divider />

        {days.map((day) => (
          <Box key={day.id} sx={{ marginTop: '48px' }}>
            {day.editMode ?
              <TextField
                autoFocus
                margin="none"
                fullWidth
                hiddenLabel
                id="edit-day-title-textfield"
                defaultValue={day.title}
                size="small"
                variant="filled"
                onBlur={async (e) => await updateDayTitle(day.id, e.target.value)}
                onKeyDown={(e) => handleEnterKeyPress(e)}
                InputLabelProps={{ shrink: false }}
                InputProps={{ style: { paddingRight: '24px' }, disableUnderline: true, endAdornment: <InputAdornment position="end"><IconButton onClick={(e) => e.currentTarget.blur()} sx={{ padding: 0, '&:hover': { color: 'primary.main' } }} aria-label="toggle password visibility" edge="end">{<CheckIcon />}</IconButton></InputAdornment> }}
                inputProps={{ style: { fontSize: 24, padding: 0 } }}
              /> :
              <Typography sx={{ display: 'inline-block' }} variant="h5">{day.title}</Typography>
            }

            {day.editMode ? null : <IconButton sx={{ padding: 0, marginLeft: '4px', bottom: '4px', '&:hover': { color: 'primary.main' } }} size="medium" aria-label='edit-day' onClick={async () => await editDayTitle(day.id)} ><EditIcon fontSize='inherit' /></IconButton>}
            {day.editMode ? null : <IconButton sx={{ padding: 0, bottom: '4px', '&:hover': { color: 'error.main' } }} size="medium" aria-label='remove-day' onClick={() => setConfirmDayDeleteData([true, day.id])}><RemoveCircleIcon fontSize='inherit' /></IconButton>}

            <TableContainer component={Paper}>
              <Table aria-label="days-table">
                <TableBody>
                  {day.legs.map((leg, index) => (
                    <TableRow key={index} sx={{ '& > *': { borderBottom: 'unset' } }}>
                      <TableCell component="th" scope="row">

                        {leg.editMode ?
                          <TextField
                            autoFocus
                            margin="none"
                            fullWidth
                            hiddenLabel
                            id="edit-day-title-textfield"
                            defaultValue={leg.title}
                            size="small"
                            variant="filled"
                            onBlur={async (e) => await updateLegTitle(day.id, leg.id, e.target.value)}
                            onKeyDown={(e) => handleEnterKeyPress(e)}
                            InputLabelProps={{ shrink: false }}
                            InputProps={{ style: { paddingRight: '24px' }, disableUnderline: true, endAdornment: <InputAdornment position="end"><IconButton onClick={(e) => e.currentTarget.blur()} sx={{ padding: 0, '&:hover': { color: 'primary.main' } }} aria-label="toggle password visibility" edge="end">{<CheckIcon />}</IconButton></InputAdornment> }}
                            inputProps={{ style: { padding: 0 } }}
                          /> :
                          <Typography variant="body1">{leg.title}</Typography>
                        }
                      </TableCell>
                      {leg.editMode ? null : <TableCell sx={{ padding: '2px', width: '34px' }}><IconButton sx={{ '&:hover': { color: 'primary.main' } }} aria-label="edit-route" component={RouterLink} to="/editor" state={{ dayId: day.id, leg: leg }}><MapIcon /></IconButton></TableCell>}
                      
                      {leg.editMode ? null : <TableCell sx={{ padding: '2px', width: '34px' }}><IconButton sx={{ '&:hover': { color: 'primary.main' } }} aria-label="edit-title" onClick={async () => await editLegTitle(day.id, leg.id)}><EditIcon /></IconButton></TableCell>}
                      {leg.editMode ? null : <TableCell sx={{ padding: '2px', width: '34px' }}><IconButton sx={{ '&:hover': { color: 'primary.main' } }} aria-label="delete-row" onClick={() => setConfirmLegDeleteData([true, [day.id, leg.id]])}><DeleteIcon /></IconButton></TableCell>}
                    </TableRow>

                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <IconButton sx={{ display: 'flex', margin: 'auto', position: 'relative', bottom: '12px', backgroundColor: '#A8A8A8', padding: 0, color: 'white', '&:hover': { color: 'white', backgroundColor: 'primary.main' } }} onClick={async () => await addLeg(day.id)}><AddCircleIcon /></IconButton>
          </Box>
        ))}
      </Box>

      <Dialog open={addDayDialogOpen} onClose={handleDayClose} fullWidth>
        <DialogTitle>Add New Day</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the title of the day:
          </DialogContentText>
          <AutoFocusTextField
            margin="dense"
            id="day_title"
            placeholder='Title'
            value={dayTitleValue}
            fullWidth
            variant="standard"
            InputLabelProps={{ shrink: false }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDayTitleValue(e.target.value)}
            onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => handleEnterKeyPressOnDialog(e)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDayClose}>Cancel</Button>
          <Button disabled={dayTitleValue.length < 1} onClick={onAddDay}>Add Day</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmDayDeleteData[0]}
        onClose={handleConfirmDeleteClose}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Do you really want to delete this day? This process cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDeleteClose}>Cancel</Button>
          <Button onClick={onDeleteDay} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmLegDeleteData[0]}
        onClose={handleConfirmDeleteClose}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Do you really want to delete this leg? This process cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDeleteClose}>Cancel</Button>
          <Button onClick={onDeleteLeg} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Home;
