import React, {PropsWithChildren} from 'react';
import { Navigate } from "react-router-dom";
import {User} from "./App";

type ProtectedRouteProps = {
    user?: User,
}
const ProtectedRoute = ({ user, children }: PropsWithChildren<ProtectedRouteProps>) => {
    if (!user) {
        return <Navigate to="/signin" replace />;
    }

    return <>
        {children}
    </>
};

export default ProtectedRoute;
