enum WaypointType {
  Start = "START",
  End = "END",
  Waypoint = "WAYPOINT",
  GasStation = "GAS_STATION",
  Hotel = "HOTEL",
  ShortBreak = "SHORT_BREAK",
  LongBreak = "LONG_BREAK",
  LunchBreak = "LUNCH_BREAK",
};

export default WaypointType;