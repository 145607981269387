import React from "react";

interface DirectionsRendererProps extends google.maps.DirectionsRendererOptions {
    onDirectionsChanged?: (result: google.maps.DirectionsResult | null) => void;
}

const DirectionsRenderer: React.FC<DirectionsRendererProps> = ({onDirectionsChanged, ...options}) => {
    const [renderer, setRenderer] = React.useState<google.maps.DirectionsRenderer>();
    
    React.useEffect(() => {
        if (!renderer) {
            setRenderer(new google.maps.DirectionsRenderer());
        }
        
        // remove renderer from map on unmount
        return () => {
            if (renderer) {
                renderer.setMap(null);
            }
        };
    }, [renderer]);
    
    React.useEffect(() => {
        if (renderer) {
            renderer.setOptions(options);
        }
    }, [renderer, options]);

    React.useEffect(() => {
        if (renderer) {
            google.maps.event.clearListeners(renderer, "directions_changed");

            if(onDirectionsChanged) {
                renderer.addListener('directions_changed', function () {
                    var result = renderer.getDirections();
                    onDirectionsChanged(result);
                });
            }
        }

    }, [renderer, onDirectionsChanged]);
    
    return null;
};

export default DirectionsRenderer;
