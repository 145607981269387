
import React from 'react';
import TextField from '@mui/material/TextField';
//@ts-ignore
function AutoFocusTextField(props) {
  const inputRef = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      inputRef.current!.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return <TextField inputRef={inputRef} {...props} />;
}

export default AutoFocusTextField;