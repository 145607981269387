import React, { useRef, useEffect } from 'react';
import Waypoint from './Waypoint';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { DragDropContext, Droppable, Draggable, ResponderProvided } from '@hello-pangea/dnd';
import type { DropResult, DraggableStyle } from "@hello-pangea/dnd";
import { indexToAlpha } from './Utils';

type SidebarProps = {
  waypoints: Waypoint[],
  legTitle: string,
  onDeleteClick: (id: string) => void,
  onTypeChange: (id: string, newType: string) => void,
  onDragEnd: (result: DropResult) => void,
  onSave: () => void,
};

const Sidebar = ({ waypoints, legTitle, onDeleteClick, onTypeChange, onDragEnd, onSave }: SidebarProps) => {

  const lastRowRef = useRef<HTMLTableRowElement>(null);

  const scrollToBottom = () => {
    if (lastRowRef.current != null) {
      let lref = lastRowRef.current!;
      lref.scrollIntoView({ behavior: "smooth" });
    }
  }

  useEffect(() => {
    scrollToBottom();
  }, [waypoints]);



  const getItemStyle = (isDragging: boolean, draggableStyle: DraggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
      background: "rgb(235,235,235)"
    })
  })

  const DraggableComponent = (id: string, index: number) => (props: any) => {
    return (
      <Draggable draggableId={id} index={index}>
        {(provided, snapshot) => (
          <TableRow
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style!)}

            {...props}
          >
            {props.children}
          </TableRow>
        )}
      </Draggable>
    )
  }

  const DroppableComponent = (
    onDragEnd: (result: DropResult, provided: ResponderProvided) => void) => (props: any) => {
      return (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={'1'} direction="vertical">
            {(provided) => {
              return (
                <TableBody ref={provided.innerRef} {...provided.droppableProps} {...props}>
                  {props.children}
                  {provided.placeholder}
                </TableBody>
              )
            }}
          </Droppable>
        </DragDropContext>
      )
    }

  return (
    <Box
      sx={{
        padding: "1rem",
        flexBasis: "350px",
        height: "calc(100vh - 32px)",
        overflow: "hidden",
        textAlign: "center",
        justifyContent: "space-between",
        flexDirection: "column",
        display: 'flex',
        backgroundColor: 'white'
      }}
    >
      <Box>
        <Typography sx={{ textAlign: 'left' }} variant="h5">{legTitle}</Typography>
        <TableContainer sx={{marginTop: 1}} component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align='center' sx={{ padding: 0 }}>#</TableCell>
                <TableCell align='left'>Type</TableCell>
                <TableCell align='left' sx={{ paddingLeft: 0 }}>Coordinates</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody component={DroppableComponent(onDragEnd)}>
              {waypoints.map((wp, index) => (
                <TableRow
                  component={DraggableComponent(wp.id, index)}
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell sx={{ padding: 0, width: '45px' }}><IconButton aria-label="dragdrop" disableRipple><DragIndicatorIcon /></IconButton></TableCell>
                  <TableCell component="th" scope="row" sx={{ padding: 0, width: '20.5px' }} align='center'>
                    {indexToAlpha(index)}
                  </TableCell>

                  <TableCell sx={{ paddingTop: '4px', paddingBottom: 0, paddingLeft: '6px', paddingRight: '0px', width: '100px' }}>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small" variant="standard">
                      <Select
                        value={wp.type}
                        onChange={(e) => onTypeChange(wp.id, e.target.value)}
                        displayEmpty
                        disableUnderline
                        sx={{fontSize:'1em'}}
                      >
                        <MenuItem value="START">
                          <em>Start</em>
                        </MenuItem>
                        <MenuItem value="WAYPOINT">Waypoint</MenuItem>
                        <MenuItem value="GAS_STATION">Gas Station</MenuItem>
                        <MenuItem value="SHORT_BREAK">Short Break</MenuItem>
                        <MenuItem value="LONG_BREAK">Long Break</MenuItem>
                        <MenuItem value="LUNCH_BREAK">Lunch Break</MenuItem>
                        <MenuItem value="HOTEL">Hotel</MenuItem>
                        <MenuItem value="END"><em>End</em></MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>

                  <TableCell sx={{ padding: 0, width: '365px' }}>{`${wp.coordinates.lat.toFixed(8)}, ${wp.coordinates.lng.toFixed(8)}`}</TableCell>
                  <TableCell sx={{ padding: 0 }}><IconButton aria-label="delete" onClick={() => onDeleteClick(wp.id)} ><DeleteIcon /></IconButton></TableCell>
                </TableRow>
              ))}
              <TableRow ref={lastRowRef} />
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Button sx={{ marginTop: '16px' }} variant="contained" onClick={onSave}>Save</Button>
    </Box>
  );
};

export default Sidebar;
