import WaypointType from "./WaypointType";

export function mapStringToWaypointType(value: string): WaypointType {
  switch (value) {
    case "START":
      return WaypointType.Start;
    case "WAYPOINT":
      return WaypointType.Waypoint;
    case "GAS_STATION":
      return WaypointType.GasStation;
    case "HOTEL":
      return WaypointType.Hotel;
    case "SHORT_BREAK":
      return WaypointType.ShortBreak;
    case "LONG_BREAK":
      return WaypointType.LongBreak;
    case "LUNCH_BREAK":
      return WaypointType.LunchBreak;
    default:
      return WaypointType.End
  }
}

export function indexToAlpha(num = 1): string {
  if (num >= 26) return 'AA';
  const A = 'A'.charCodeAt(0);
  let numberToCharacter = (number: number) => {
    return String.fromCharCode(A + number);
  };

  return numberToCharacter(num);
}
