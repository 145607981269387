import React, { useRef, useState } from "react";
import TextField from '@mui/material/TextField';

type Props = {
    options?: google.maps.places.AutocompleteOptions,
    placeChanged?: (place: google.maps.places.PlaceResult, searchRef:HTMLInputElement) => void;
}

const AutocompleteBox: React.FC<Props> = ({ options, placeChanged }) => {
    const [autocompleteWidget, setAutocompleteWidget] = React.useState<google.maps.places.Autocomplete>();
    const searchRef = useRef<HTMLInputElement>();
    const [hidden, setHidden] = useState(true);

    React.useEffect(() => {
        if (!autocompleteWidget) {
            setAutocompleteWidget(
                // @ts-ignore
                new google.maps.places.Autocomplete(searchRef.current, {
                    fields: ["place_id", "geometry.location"],
                })
            );
        }
    }, [searchRef, autocompleteWidget]);

    React.useEffect(() => {
        if (autocompleteWidget) {
            if (options)
                autocompleteWidget.setOptions(options);
        }
    }, [autocompleteWidget, options]);

    React.useEffect(() => {
        setTimeout(() => {
            setHidden(false);
        }, 500);

    }, []);

    React.useEffect(() => {
        if (autocompleteWidget){
            google.maps.event.clearListeners(autocompleteWidget, "place_changed");

            if(placeChanged) {
                autocompleteWidget.addListener("place_changed", function(){
                    placeChanged(autocompleteWidget.getPlace(), searchRef.current!);
                });
            }
        }

    },[autocompleteWidget, placeChanged]);

    return (
        <TextField
            variant='outlined'
            id="outlined-search"
            inputRef={searchRef}
            type="search"
            placeholder='Search Map'
            size='small'
            InputLabelProps={{ shrink: false }}
            sx={{
                position: 'absolute',
                top: '10px',
                left: '190px',
                width: '390px',
                visibility: hidden ? 'hidden' : 'visible',
                borderRadius: '3px',
                boxShadow: 'rgb(0 0 0 / 30%) 0px 1px 4px -1px',
                "& .MuiOutlinedInput-root.Mui-focused": {
                    "& > fieldset": {
                        borderColor: "rgba(0, 0, 0, 0.23)",
                        border: "none"
                    }
                },
                "& .MuiOutlinedInput-root:hover": {
                    "& > fieldset": {
                        borderColor: "rgba(0, 0, 0, 0.23)",
                        border: "none"
                    }
                },
                "& .MuiOutlinedInput-root": {
                    "& > fieldset": {
                        border: "none"
                    },
                    background: "white",
                    borderRadius: '2px',
                    border: 'none'
                },
            }}
        />
    );
};

export default AutocompleteBox;